import type { CellContext } from '@tanstack/react-table'

import { formatDate } from '@mntn-dev/utilities'
import { Tooltip } from '../tooltip/tooltip.tsx'

export const cellTemplate = {
  text: <TData extends {}>(props: CellContext<TData, string>) => {
    return (
      <Tooltip content={props.getValue()}>
        <span>{props.getValue()}</span>
      </Tooltip>
    )
  },
  date: <TData extends {}>(props: CellContext<TData, Date>) =>
    props.getValue()
      ? formatDate(props.getValue(), 'medium-date-alt').toUpperCase()
      : '',
  currency:
    <TData extends {}>(
      formatter: (val?: number, fractionDigits?: number) => string
    ) =>
    (props: CellContext<TData, number>) =>
      formatter(props.getValue()),
}
