import type { PropsWithChildren } from 'react'

import { type TestIds, getTestProps } from '../../../utils'

export const PageHeaderTitleArea = ({
  children,
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<{} & TestIds>) => {
  return (
    <div className="flex grow flex-col justify-start">
      <div
        {...getTestProps({ dataTestId, dataTrackingId })}
        className="flex flex-col items-start justify-start gap-1"
      >
        {children}
      </div>
    </div>
  )
}
