import { cn } from '@mntn-dev/ui-utilities'
import type { HTMLProps, PropsWithChildren } from 'react'

import { DropzoneEmpty } from './dropzone-empty.tsx'
import { DropzoneOverlay } from './dropzone-overlay.tsx'
import { DropzoneWithSingleImage } from './dropzone-with-single-image.tsx'

type DropzoneProps = PropsWithChildren<
  Readonly<{
    rootProps: HTMLProps<HTMLDivElement>
    isDragging: boolean
    canUpload: boolean
    className: string
  }>
>
const Dropzone = ({
  rootProps,
  isDragging,
  canUpload = true,
  className,
  children,
}: DropzoneProps) => {
  return (
    <span
      {...rootProps}
      className={cn(
        `group ${className}`,
        canUpload && (isDragging ? 'cursor-s-resize' : 'cursor-pointer')
      )}
    >
      {children}
    </span>
  )
}

const DropzoneComponent = Object.assign(Dropzone, {
  Empty: DropzoneEmpty,
  SingleImage: DropzoneWithSingleImage,
  Overlay: DropzoneOverlay,
})

export { DropzoneComponent as Dropzone, type DropzoneProps }
