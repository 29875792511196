import { getTestProps, type TestIds } from '../../../utils'
import NoImage from './no-image.tsx'

type HeaderImageProps = TestIds &
  Readonly<{
    imageSrc?: string
  }>

const HeaderImage = ({
  imageSrc,
  dataTestId,
  dataTrackingId,
}: HeaderImageProps) => {
  return (
    <div>
      {imageSrc ? (
        <img
          className="h-40 max-w-40 flex-auto rounded-lg border border-slate-100 object-cover"
          src={imageSrc}
          {...getTestProps({ dataTestId, dataTrackingId })}
          alt="project attachment"
        />
      ) : (
        <NoImage />
      )}
    </div>
  )
}

export { HeaderImage, type HeaderImageProps }
