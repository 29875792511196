import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

type DropzoneWithSingleImageProps = PropsWithChildren<
  Readonly<{
    url?: string
    className: string
    altText: string
  }>
>
export const DropzoneWithSingleImage = ({
  url,
  className,
  altText,
}: DropzoneWithSingleImageProps) => {
  return (
    <img
      className={cn(`absolute left-0 top-0 ${className}`, {
        hidden: !url,
      })}
      src={url}
      alt={altText}
    />
  )
}
