import { cn } from '@mntn-dev/ui-utilities'

import { useSizing } from '../../hooks/use-sizing.ts'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonHeadingProps = SkeletonProps

const SkeletonHeading = ({ className, ...props }: SkeletonHeadingProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      className={cn('mb-2 animate-pulse', {
        ...Object.values(sizing),
        className,
      })}
    >
      <Skeleton variant="rounded" width="3/6" height="4" />
    </div>
  )
}

export { SkeletonHeading, type SkeletonHeadingProps }
