import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

type DropZoneOverlayProps = PropsWithChildren<
  Readonly<{
    show: boolean
    className: string
  }>
>
export const DropzoneOverlay = ({
  show,
  className,
  children,
}: DropZoneOverlayProps) => {
  return (
    <div
      className={cn(
        `absolute left-0 top-0 ${className}`,
        show ? 'flex' : 'hidden'
      )}
    >
      {children}
    </div>
  )
}
