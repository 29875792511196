import type { PropsWithChildren, ReactNode } from 'react'

import type { TestIds } from '../../../utils'
import { Breadcrumbs } from '../../breadcrumbs'
import { Heading } from '../../heading'

export const PageHeaderBreadcrumbs = ({
  title,
  crumbs,
  dataTestId,
}: PropsWithChildren<
  {
    title: string
    crumbs?: ReactNode[]
  } & TestIds
>) => {
  return (
    <Breadcrumbs dividerSize="2xl">
      <Heading dataTestId={`${dataTestId}-main-title`}>{title}</Heading>
      {crumbs?.map((crumb, index) => (
        <Heading
          key={`page-header-breadcrumb-${index + 1}`}
          textColor="secondary"
          fontSize="2xl"
          className="inline"
          dataTestId={`${dataTestId}-breadcrumb-${index + 1}`}
        >
          {crumb}
        </Heading>
      ))}
    </Breadcrumbs>
  )
}
