import type { PropsWithChildren } from 'react'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import { type TestIds, getTestProps } from '../../../utils'
import { Button } from '../../buttons'

export const PageHeaderLink = ({
  onClick,
  textColor = 'primary',
  isLoading = false,
  children,
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<
  {
    onClick: () => void
    isLoading?: boolean
    textColor?: ThemeTextColor
  } & TestIds
>) => {
  return (
    <Button
      size="xs"
      variant="text"
      onClick={onClick}
      iconLeft="ArrowLeftIcon"
      textColor={textColor}
      loading={isLoading}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </Button>
  )
}
