import type { PropsWithChildren } from 'react'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import type { TestIds } from '../../../utils'
import { Heading } from '../../heading'

export const PageHeaderTitle = ({
  title,
  textColor,
  children,
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<
  { title: string; textColor?: ThemeTextColor } & TestIds
>) => {
  return (
    <div className="flex gap-4">
      <Heading
        fontSize="4xl"
        fontWeight="bold"
        textColor={textColor || 'primary'}
        dataTestId={dataTestId}
        dataTrackingId={dataTrackingId}
        className="leading-snug"
      >
        {title}
      </Heading>
      {children}
    </div>
  )
}
