import type React from 'react'

import { getTestProps, type TestIds } from '../../utils'
import { FormLayout } from './form-layout.tsx'
import { FormSectionSubtitle } from './form-section-subtitle.tsx'
import { FormSectionTitle } from './form-section-title.tsx'
import { FormSection } from './form-section.tsx'

type FormProps = React.PropsWithChildren<
  Readonly<
    TestIds &
      React.FormHTMLAttributes<HTMLFormElement> & {
        gap?: string
      }
  >
>

const FormComponent = ({
  gap = 'gap-8',
  children,
  dataTestId,
  dataTrackingId,
  ...formProps
}: FormProps) => (
  <form {...formProps} {...getTestProps({ dataTestId, dataTrackingId })}>
    <div className={`flex h-full flex-col ${gap}`}>{children}</div>
  </form>
)

const FormNamespace = Object.assign(FormComponent, {
  Layout: FormLayout,
  Section: FormSection,
  SectionTitle: FormSectionTitle,
  SectionSubtitle: FormSectionSubtitle,
})

export { FormNamespace as Form, type FormProps }
