type FormSectionTitleProps = Readonly<{
  children: string
}>

const FormSectionTitle = ({ children }: FormSectionTitleProps) => (
  <h2 className="text-med font-semibold leading-7 text-slate-900">
    {children}
  </h2>
)

export { FormSectionTitle, type FormSectionTitleProps }
