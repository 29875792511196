import type { PropsWithChildren } from 'react'

import { gapMap, type ThemeGapValue } from '@mntn-dev/ui-theme'

type ResponsiveGridLayoutProps = Readonly<
  PropsWithChildren<{
    childSize: 'sm' | 'md' | 'lg' | 'xl'
    className?: string
    gap?: ThemeGapValue
  }>
>

const gridSizeMap = {
  sm: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6',
  md: 'grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3',
  lg: 'grid-cols-1 xl:grid-cols-2',
  xl: 'grid-cols-1 2xl:grid-cols-2',
}

export const ResponsiveGridLayout = ({
  childSize,
  className,
  gap,
  children,
}: ResponsiveGridLayoutProps) => {
  const gridSizeClasses = gridSizeMap[childSize]
  return (
    <div
      className={`grid place-content-stretch ${gapMap[gap ?? '6']} ${gridSizeClasses} ${className} [&>*]:m-auto`}
    >
      {children}
    </div>
  )
}
