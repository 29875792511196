import type { PropsWithChildren } from 'react'

import { type TestIds, getTestProps } from '../../../utils'

export const PageHeaderTitleInfo = ({
  children,
  dataTestId,
  dataTrackingId,
}: PropsWithChildren<TestIds>) => {
  return (
    <div
      className="flex flex-row items-center justify-start gap-4 h-10"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </div>
  )
}
