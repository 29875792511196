import type { ReactNode } from 'react'

import {
  NavigationAvatarItem,
  type NavigationAvatarItemProps,
} from './navigation-avatar-item.tsx'
import { NavigationButtonItem } from './navigation-button-item.tsx'
import { NavigationHeader } from './navigation-header.tsx'
import {
  NavigationIconItem,
  type NavigationIconItemProps,
} from './navigation-icon-item.tsx'
import { NavigationItems } from './navigation-items.tsx'
import { NavigationMain } from './navigation-main.tsx'

type NavigationProps = {
  children: ReactNode
}

const NavigationComponent = ({ children }: NavigationProps) => {
  return (
    <div className="flex h-full grow flex-col items-center gap-y-4 overflow-y-auto py-6">
      {children}
    </div>
  )
}

const NavigationNamespace = Object.assign(NavigationComponent, {
  AvatarItem: NavigationAvatarItem,
  ButtonItem: NavigationButtonItem,
  IconItem: NavigationIconItem,
  Header: NavigationHeader,
  Items: NavigationItems,
  Main: NavigationMain,
})

export {
  NavigationNamespace as Navigation,
  type NavigationProps,
  type NavigationIconItemProps,
  type NavigationAvatarItemProps,
}
