import { cn } from '@mntn-dev/ui-utilities'
import type { HTMLProps, PropsWithChildren } from 'react'

type ProjectThumbnailEmptyProps = PropsWithChildren<
  Readonly<{
    className: string
    inputProps: HTMLProps<HTMLInputElement>
  }>
>

export const DropzoneEmpty = ({
  className,
  inputProps,
  children,
}: ProjectThumbnailEmptyProps) => {
  return (
    <div className={cn(`absolute left-0 top-0 ${className}`)}>
      <input {...inputProps} />
      {children}
    </div>
  )
}
