export const MntnLogo = ({
  width = 24,
  height = 21,
}: {
  width?: number
  height?: number
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 21"
      fill="none"
    >
      <g clipPath="url(#clip0_7806_14392)">
        <path
          d="M11.7163 10.012C11.7 9.99585 11.7 9.99585 11.7 9.99585C11.7 9.97965 11.6838 9.97965 11.6838 9.96345C11.5213 9.68805 11.3588 9.39644 11.1801 9.08864L6.22407 0.50258C6.19157 0.43778 6.14282 0.389179 6.09408 0.340579C5.88284 0.129977 5.59035 0.0165766 5.28162 0.0327768H1.21933C0.553116 0.0165766 0.000644406 0.567381 0.000644406 1.24779V19.2947C-0.0156047 19.7645 0.27688 20.1695 0.715607 20.3315C1.10559 20.4611 1.52806 20.3639 1.82055 20.0723L5.46036 16.4435C5.78534 16.1195 6.28907 16.0385 6.6953 16.2491L11.2126 18.5333C11.6675 18.7601 12.2038 18.6305 12.5287 18.2417L14.8199 15.3743L11.7163 10.012Z"
          fill="#1AC9AA"
        />
        <path
          d="M22.7318 0C21.4481 0 19.8394 0 18.6695 0C18.2308 0 17.8245 0.226802 17.6133 0.615605C16.1509 3.19142 13.7785 7.50066 12.2511 9.97927C12.1536 10.1251 11.9586 10.1899 11.8123 10.0927C11.7798 10.0765 11.7473 10.0441 11.7148 10.0117L11.7311 10.0279L14.8347 15.3901L17.2233 12.3445C17.5808 11.8909 18.247 11.8099 18.7182 12.1825C18.7507 12.2149 18.7832 12.2311 18.7995 12.2635L22.1306 15.5845C22.2768 15.7465 22.4718 15.8437 22.6993 15.8923C23.268 16.0057 23.8367 15.6169 23.9342 15.0499C23.9505 14.9851 23.9505 14.9203 23.9505 14.8555V1.21501C23.9505 0.550804 23.398 0 22.7318 0Z"
          fill="url(#paint0_linear_7806_14392)"
        />
        <path
          d="M11.7148 9.99512C11.7148 9.99512 11.7148 9.99512 11.7311 10.0113L14.8347 15.3736L11.7148 10.0113V9.99512Z"
          fill="#1AC9AA"
        />
        <path
          d="M11.7148 9.99512C11.7148 9.99512 11.7148 9.99512 11.7311 10.0113L14.8347 15.3736L11.7148 10.0113V9.99512Z"
          fill="url(#paint1_linear_7806_14392)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7806_14392"
          x1="12.1837"
          y1="11.5976"
          x2="24.5849"
          y2="6.29257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#045562" />
          <stop offset="0.03" stopColor="#05606F" />
          <stop offset="0.1" stopColor="#06778A" />
          <stop offset="0.18" stopColor="#088BA0" />
          <stop offset="0.27" stopColor="#0999B1" />
          <stop offset="0.39" stopColor="#09A3BC" />
          <stop offset="0.55" stopColor="#0AA9C3" />
          <stop offset="1" stopColor="#0AABC5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7806_14392"
          x1="12.7452"
          y1="12.9114"
          x2="25.148"
          y2="7.60475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#045562" />
          <stop offset="0.03" stopColor="#05606F" />
          <stop offset="0.1" stopColor="#06778A" />
          <stop offset="0.18" stopColor="#088BA0" />
          <stop offset="0.27" stopColor="#0999B1" />
          <stop offset="0.39" stopColor="#09A3BC" />
          <stop offset="0.55" stopColor="#0AA9C3" />
          <stop offset="1" stopColor="#0AABC5" />
        </linearGradient>
        <clipPath id="clip0_7806_14392">
          <rect width="24" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
