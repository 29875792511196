import { type ThemeTextColor, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { fontSizeMap } from '../../classes/font-size.ts'
import type { FontSize } from '../../types/font.ts'

type CurrencyContainerProps = Readonly<{
  className?: string
  text: string
  textColor?: ThemeTextColor
  fontSize?: FontSize
}>

export const CurrencyLabel = ({
  className = '',
  text,
  textColor,
  fontSize,
}: CurrencyContainerProps) => {
  return (
    <span
      className={cn(
        'flex flex-col self-end leading-loose uppercase font-semibold whitespace-nowrap',
        fontSize ? fontSizeMap[fontSize] : 'text-sm',
        textColor ? themeTextColorMap[textColor] : themeTextColorMap.tertiary,
        className
      )}
    >
      {text}
    </span>
  )
}
