import { getTestProps, type TestIds } from '../../../utils'
import { Heading } from '../../heading'

type HeaderTitleProps = TestIds &
  Readonly<{
    title: string
  }>

const HeaderTitle = ({
  title,
  dataTestId,
  dataTrackingId,
}: HeaderTitleProps) => {
  return (
    <div
      className="leading-10"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <Heading fontWeight="semibold" textColor="primary">
        {title}
      </Heading>
    </div>
  )
}

export { HeaderTitle, type HeaderTitleProps }
