import type React from 'react'

import { getTestProps, type TestIds } from '../../../utils'

type HeaderTopLineProps = TestIds &
  Readonly<{
    children: React.ReactNode
  }>
const HeaderTopLine = ({
  children,
  dataTestId,
  dataTrackingId,
}: HeaderTopLineProps) => {
  return (
    <div
      className="w-full leading-5"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </div>
  )
}

export { HeaderTopLine, type HeaderTopLineProps }
