import type React from 'react'

import { type ThemeTextColor, themeTextColorMap } from '@mntn-dev/ui-theme'

import { ListItem } from './list-item.tsx'

type ListProps = React.HTMLAttributes<HTMLUListElement> &
  Readonly<{ color?: ThemeTextColor }>

const List = ({ color, ...props }: ListProps) => {
  return (
    <ul
      className={`list-disc px-6 ${themeTextColorMap[color ?? 'primary']}`}
      {...props}
    />
  )
}

const ListNamespace = Object.assign(List, {
  Item: ListItem,
})

export { ListNamespace as List, type ListProps }
