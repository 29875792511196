import { type ThemeTextColor, themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

type ListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  markerColor?: ThemeTextColor
}

const ListItem = ({ markerColor, className = '', ...props }: ListItemProps) => {
  return (
    <li
      className={cn(
        className,
        markerColor && `marker:${themeTextColorMap[markerColor]}`
      )}
      {...props}
    />
  )
}

export { ListItem, type ListItemProps }
