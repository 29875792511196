import type React from 'react'

import {
  FeedCommentInput,
  type CommentFormValues,
} from './feed-comment-input.tsx'
import { FeedEmpty } from './feed-empty.tsx'
import { FeedItemList } from './feed-item-list.tsx'
import { FeedItem } from './feed-item.tsx'
import { FeedToolbar } from './feed-toolbar.tsx'
import { FeedLinkButton } from './feed-link-button.tsx'
import { FeedTag } from './feed-tag.tsx'
import { FeedSummary } from './feed-summary.tsx'
import { FeedQuote } from './feed-quote.tsx'
import { FeedAnnotations } from './feed-annotations.tsx'
import { FeedText } from './feed-text.tsx'

type FeedProps = Readonly<{
  children: React.ReactNode
}>

const FeedComponent = ({ children }: FeedProps) => {
  return <div className="flex w-full flex-col gap-4">{children}</div>
}

const FeedNamespace = Object.assign(FeedComponent, {
  Empty: FeedEmpty,
  ItemList: FeedItemList,
  Item: FeedItem,
  Toolbar: FeedToolbar,
  CommentInput: FeedCommentInput,
  Tag: FeedTag,
  LinkButton: FeedLinkButton,
  Summary: FeedSummary,
  Quote: FeedQuote,
  Annotations: FeedAnnotations,
  Text: FeedText,
})

export { FeedNamespace as Feed, type CommentFormValues, type FeedProps }
