import { cn } from '@mntn-dev/ui-utilities'
import type { HTMLAttributes, PropsWithChildren } from 'react'

import { type TestIds, getTestProps } from '../../../utils'
import { PageHeaderBreadcrumbs } from './page-breadcrumbs.tsx'
import { PageHeaderControls } from './page-header-controls.tsx'
import { PageHeaderLink } from './page-header-link.tsx'
import { PageHeaderTitleArea } from './page-header-title-area.tsx'
import { PageHeaderTitleInfo } from './page-header-title-info.tsx'
import { PageHeaderTitle } from './page-header-title.tsx'

type PageHeaderProps = PropsWithChildren<
  TestIds & Readonly<HTMLAttributes<HTMLElement>>
>

const PageHeader = ({
  children,
  dataTestId,
  dataTrackingId,
}: PageHeaderProps) => {
  return (
    <div
      {...getTestProps({ dataTestId, dataTrackingId })}
      className={cn(
        'flex w-full shrink-0 basis-0 flex-row items-center gap-6 self-stretch pb-8 justify-between'
      )}
    >
      {children}
    </div>
  )
}

const PageHeaderNamespace = Object.assign(PageHeader, {
  Breadcrumbs: PageHeaderBreadcrumbs,
  Link: PageHeaderLink,
  Title: PageHeaderTitle,
  TitleArea: PageHeaderTitleArea,
  TitleInfo: PageHeaderTitleInfo,
  Controls: PageHeaderControls,
})

export { PageHeaderNamespace as PageHeader, type PageHeaderProps }
