import {
  type ThemeTextColor,
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'

import { cn } from '@mntn-dev/ui-utilities'
import type { HTMLAttributes, PropsWithChildren } from 'react'
import type { TupleToUnion } from 'type-fest'
import { type TestIds, getTestProps } from '../../utils'
import { Icon, type IconProps } from '../icon'

const TagTypes = [
  'default',
  'info',
  'success',
  'warning',
  'error',
  'notice',
] as const

type TagType = TupleToUnion<typeof TagTypes>

const TagVariants = ['primary', 'secondary', 'tertiary'] as const

type TagVariant = TupleToUnion<typeof TagVariants>

type TagProps = Readonly<
  HTMLAttributes<HTMLSpanElement> &
    PropsWithChildren<
      TestIds & {
        type?: TagType
        icon?: Omit<IconProps, 'size' | 'color'>
        variant?: TagVariant
        dismissible?: boolean
        onDismiss?: () => void
      }
    >
>

const tagColorClassMap: Record<
  TagType,
  {
    bgColor: string
    bgColorLight: string
    textColor: ThemeTextColor
    borderColor: string
  }
> = {
  info: {
    bgColor: 'bg-primary-blue',
    bgColorLight: 'bg-primary-blue/10',
    textColor: 'info',
    borderColor: 'ring-1 ring-inset ring-primary-blue',
  },
  success: {
    bgColor: 'bg-primary-green',
    bgColorLight: 'bg-primary-green/10',
    textColor: 'positive',
    borderColor: 'ring-1 ring-inset ring-primary-green',
  },
  warning: {
    bgColor: 'bg-primary-yellow',
    bgColorLight: 'bg-primary-yellow/10',
    textColor: 'caution',
    borderColor: 'ring-1 ring-inset ring-primary-yellow',
  },
  error: {
    bgColor: 'bg-primary-red',
    bgColorLight: 'bg-primary-red/10',
    textColor: 'negative',
    borderColor: 'ring-1 ring-inset ring-primary-red',
  },
  default: {
    bgColor: 'bg-white',
    bgColorLight: 'bg-white/10',
    textColor: 'secondary',
    borderColor: 'ring-1 ring-inset ring-gray-800',
  },
  notice: {
    bgColor: 'bg-primary-purple',
    bgColorLight: 'bg-primary-purple/10',
    textColor: 'notice',
    borderColor: 'ring-1 ring-inset ring-primary-purple',
  },
}

const Tag = ({
  children,
  type = 'default',
  icon,
  variant = 'primary',
  dismissible,
  dataTestId,
  dataTrackingId,
  onDismiss,
  ...props
}: TagProps) => {
  const { bgColor, bgColorLight, textColor, borderColor } =
    tagColorClassMap[type]

  const tagColorMap: Record<TagVariant, ThemeTextColor> = {
    primary: 'primary',
    secondary: textColor,
    tertiary: 'primary-inverse',
  }

  const tagColor = tagColorMap[variant]

  return (
    <span
      {...props}
      className={cn(
        `inline-flex items-center justify-center gap-x-1 h-[22px] px-2 text-xs font-semibold rounded-sm uppercase ${themeTextColorMap[tagColor]} before:${themeBackgroundBlurMap['blur-md']} overflow-hidden min-w-fit max-w-full`,
        {
          [`${borderColor} ${bgColorLight}`]: variant === 'primary',
          [`border ${themeBorderColorMap.muted} ${themeBackgroundMap['container-tertiary']}`]:
            variant === 'secondary',
          [`${bgColor}`]: variant === 'tertiary',
        }
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {icon && (
        <Icon
          {...{
            ...icon,
            size: 'xs',
          }}
          color={variant === 'primary' ? textColor : tagColor}
        />
      )}
      <span className="whitespace-nowrap text-ellipsis max-w-full overflow-hidden">
        {children}
      </span>

      {dismissible && (
        <button
          type="button"
          className="group relative -mr-1 h-3.5 w-3.5 rounded-sm"
          onClick={onDismiss}
        >
          <span className="sr-only">Remove</span>
          <Icon
            fill="solid"
            name="XMarkIcon"
            size="xs"
            color={variant === 'primary' ? textColor : tagColor}
          />
          <span className="absolute -inset-1" />
        </button>
      )}
    </span>
  )
}

export {
  Tag,
  type TagProps,
  TagTypes,
  type TagType,
  TagVariants,
  type TagVariant,
}
