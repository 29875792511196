import { getTestProps, type TestIds } from '../../../utils'

type NoImageProps = TestIds & {}

const NoImage = ({ dataTestId, dataTrackingId }: NoImageProps) => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <g clipPath="url(#clip0_8277_52193)">
        <rect width="160" height="160" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81 77C81.5523 77 82 77.4477 82 78V84H88C88.5523 84 89 84.4477 89 85C89 85.5523 88.5523 86 88 86H82V92C82 92.5523 81.5523 93 81 93C80.4477 93 80 92.5523 80 92V86H74C73.4477 86 73 85.5523 73 85C73 84.4477 73.4477 84 74 84H80V78C80 77.4477 80.4477 77 81 77Z"
          fill="#8F8F8F"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="159"
        height="159"
        rx="7.5"
        stroke="#CCCCCC"
        strokeDasharray="8 8"
      />
      <defs>
        <clipPath id="clip0_8277_52193">
          <rect width="160" height="160" rx="8" fill="white" />
        </clipPath>
      </defs>

      <title>Empty Image</title>
    </svg>
  )
}

export default NoImage
