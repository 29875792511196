import type React from 'react'

import { getTestProps, type TestIds } from '../../utils'

type FormSectionProps = Readonly<
  TestIds & {
    children: React.ReactNode
  }
>

const FormSection = ({
  children,
  dataTestId,
  dataTrackingId,
}: FormSectionProps) => (
  <section
    className="border-b border-slate-900/10 pb-12"
    {...getTestProps({ dataTestId, dataTrackingId })}
  >
    {children}
  </section>
)

export { FormSection, type FormSectionProps }
