export const CreditIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={`${className} fill-primary-green drop-shadow-glow-green`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="fill-primary-green"
    >
      <path
        d="M16.7158 2C18.3564 2 20.2158 2.23698 22.2939 2.71094L22.1846 6.8125C20.07 6.33854 18.2471 6.10156 16.7158 6.10156C15.0023 6.10156 13.6898 6.57552 12.7783 7.52344C11.9033 8.47135 11.4658 9.82031 11.4658 11.5703V20.375C11.4658 22.1979 11.9215 23.5833 12.833 24.5312C13.7809 25.4427 15.0752 25.8984 16.7158 25.8984C17.7367 25.8984 18.6299 25.8438 19.3955 25.7344C20.1611 25.625 21.0908 25.4427 22.1846 25.1875L22.2939 29.2891C20.07 29.763 18.1924 30 16.6611 30C10.0986 30 6.81738 26.6823 6.81738 20.0469V11.9531C6.81738 5.31771 10.1169 2 16.7158 2Z"
        fill="fill-primary-green"
      />
      <path
        d="M25.1833 15.6719C25.1833 18.1888 23.1429 20.2292 20.626 20.2292C18.1091 20.2292 16.0687 18.1888 16.0687 15.6719C16.0687 13.155 18.1091 11.1146 20.626 11.1146C23.1429 11.1146 25.1833 13.155 25.1833 15.6719Z"
        fill="fill-primary-green"
      />
    </svg>
  )
}
