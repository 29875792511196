import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

import { useSizing } from '../../hooks/use-sizing.ts'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonBoxProps = PropsWithChildren<SkeletonProps>

const SkeletonBox = ({ className, ...props }: SkeletonBoxProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      className={cn('animate-pulse', {
        ...Object.values(sizing),
        className,
      })}
    >
      <Skeleton width="full" height="full" variant="rectangle" />
    </div>
  )
}

export { SkeletonBox, type SkeletonBoxProps }
