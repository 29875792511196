import type { PropsWithChildren } from 'react'

import { getTestProps, type TestIds } from '../../../utils'
import { HeaderImage } from './header-image.tsx'
import { HeaderTitle } from './header-title.tsx'
import { HeaderTopLine } from './header-top-line.tsx'

type HeaderProps = PropsWithChildren<
  TestIds &
    Readonly<{
      showImage?: boolean
      imageSrc?: string
    }>
>

const HeaderComponent = ({
  showImage = true,
  imageSrc,
  dataTestId,
  dataTrackingId,
  children,
}: HeaderProps) => {
  return (
    <div
      className="flex w-full min-w-full max-w-full gap-x-6"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {showImage && <HeaderImage imageSrc={imageSrc} />}
      <div className="v-align-top flex flex-1 flex-col gap-y-4">{children}</div>
    </div>
  )
}

const HeaderNamespace = Object.assign(HeaderComponent, {
  Title: HeaderTitle,
  TopLine: HeaderTopLine,
})

export { HeaderNamespace as Header, type HeaderProps }
