import type { HTMLAttributes, PropsWithChildren } from 'react'

import { getTestProps, type TestIds } from '../../utils'
import { StatusDescription } from './status-description.tsx'
import { StatusIcon } from './status-icon.tsx'
import { StatusTitle } from './status-title.tsx'

type StatusProps = PropsWithChildren<
  TestIds & Readonly<HTMLAttributes<HTMLElement> & TestIds>
>

const Status = ({ children, dataTestId, dataTrackingId }: StatusProps) => {
  return (
    <div {...getTestProps({ dataTestId, dataTrackingId })} className="flex">
      {children}
    </div>
  )
}

const StatusComponent = Object.assign(Status, {
  Description: StatusDescription,
  Icon: StatusIcon,
  Title: StatusTitle,
})

export { StatusComponent as Status, type StatusProps }
