import { type ThemeGap, rowGapMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { ReactNode } from 'react'

type NavigationItemsProps = Pick<ThemeGap, 'rowGap'> & {
  children: ReactNode
}

const NavigationItems = ({ children, rowGap = '4' }: NavigationItemsProps) => {
  return <ul className={cn('flex flex-col', rowGapMap[rowGap])}>{children}</ul>
}

export { NavigationItems }
