import { cn } from '@mntn-dev/ui-utilities'

import { useSizing } from '../../hooks/use-sizing.ts'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonLineProps = SkeletonProps

const SkeletonLine = ({ className, ...props }: SkeletonLineProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      className={cn('animate-pulse', {
        ...Object.values(sizing),
        className,
      })}
    >
      <Skeleton variant="rounded" width="full" height="2" />
    </div>
  )
}

export { SkeletonLine, type SkeletonLineProps }
