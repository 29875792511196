import type { PropsWithChildren } from 'react'

import type { TestIds } from '../../../utils/index.ts'
import { Stack } from '../../stack/stack.tsx'

export const PageHeaderControls = ({
  children,
  ...testProps
}: PropsWithChildren<TestIds>) => {
  return (
    <Stack justifyContent="end" alignItems="center" {...testProps}>
      {children}
    </Stack>
  )
}
