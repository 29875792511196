import { cn } from '@mntn-dev/ui-utilities'

import { makeRange } from '@mntn-dev/utilities'

import { useSizing } from '../../hooks/use-sizing.ts'
import { Stack } from '../stack/stack.tsx'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonParagraphProps = SkeletonProps & { lines: number }

const SkeletonParagraph = ({
  lines,
  className,
  ...props
}: SkeletonParagraphProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      className={cn('animate-pulse', {
        ...Object.values(sizing),
        className,
      })}
    >
      <Stack direction="col" gap="2">
        {makeRange(lines - 1).map((i) => (
          <Skeleton
            key={`line-${i}`}
            variant="rounded"
            width="full"
            height="2"
          />
        ))}
        <Skeleton variant="rounded" width="5/6" height="2" />
      </Stack>
    </div>
  )
}

export { SkeletonParagraph, type SkeletonParagraphProps }
