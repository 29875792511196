import { Button, type ButtonProps } from '../../buttons/button.tsx'
import type { IconProps } from '../../icon/icon.tsx'

type NavigationButtonItemProps = Pick<IconProps, 'fill'> &
  Pick<
    ButtonProps,
    | 'id'
    | 'onClick'
    | 'disabled'
    | 'dataTestId'
    | 'dataTrackingId'
    | 'iconLeft'
    | 'iconFill'
    | 'iconColor'
    | 'variant'
    | 'size'
    | 'title'
  >

const NavigationButtonItem = ({
  variant = 'secondary',
  size = 'sm',
  ...otherProps
}: NavigationButtonItemProps) => (
  <li className="inline-flex items-center justify-center">
    <Button {...otherProps} variant={variant} size={size} circular />
  </li>
)

export { NavigationButtonItem, type NavigationButtonItemProps }
