import type { ReactNode } from 'react'

type NavigationMainProps = {
  children: ReactNode
}

const NavigationMain = ({ children }: NavigationMainProps) => (
  <nav className="flex flex-1 flex-col justify-between">{children}</nav>
)

export { NavigationMain }
