import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { AnchorHTMLAttributes, PropsWithChildren } from 'react'
import type { TextProps } from '../text/text.tsx'

type LinkProps = PropsWithChildren<
  Pick<TextProps, 'fontSize' | 'fontWeight'> &
    AnchorHTMLAttributes<HTMLAnchorElement>
>

const Link = ({ children, ...props }: LinkProps) => {
  return (
    <a {...props} className={cn('underline', themeTextColorMap.link)}>
      {children}
    </a>
  )
}

export { Link }
