export const CropLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 23V19H7C6.45 19 5.97933 18.8043 5.588 18.413C5.19667 18.0217 5.00067 17.5507 5 17V7H1V5H5V1H7V17H23V19H19V23H17ZM17 15V7H9V5H17C17.55 5 18.021 5.196 18.413 5.588C18.805 5.98 19.0007 6.45067 19 7V15H17Z"
        fill="url(#paint0_linear_7806_31915)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7806_31915"
          x1="12"
          y1="1"
          x2="12"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41C0FF" />
          <stop offset="1" stopColor="#41C0FF" stopOpacity="0.7" />
        </linearGradient>
      </defs>
    </svg>
  )
}
