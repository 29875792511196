import type React from 'react'

type FormLayoutProps = Readonly<{
  children: React.ReactNode
}>

const FormLayout = ({ children }: FormLayoutProps) => (
  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
    {children}
  </div>
)

export { FormLayout, type FormLayoutProps }
